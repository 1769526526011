import React, { Fragment } from 'react';
import { FormattedMessage as T } from 'react-intl';

export const compareData = [
  {
    title: 'Yes, we’re cheaper',
    text: <Fragment>Clinic prices start at <T id="compare.clinic">{txt => txt}</T>.<br/>Our fixed fee is <T id="compare.fixedFee">{txt => txt}</T>.</Fragment>,
    image: { url: 'pages/comparison_step1.png', alt: 'We’re affordable' },
    icon: { url: 'icons/icon_comparison_step1.svg', alt: 'Decorative icon' },
    reverse: false,
  },
  {
    title: 'How are we cheaper?',
    text: <Fragment>A digital service means less overheads, we can pass on the savings to you.</Fragment>,
    image: { url: 'pages/comparison_step2.png', alt: 'Saving you money' },
    icon: { url: 'icons/icon_comparison_step2.svg', alt: 'Decorative icon' },
    reverse: true,
  },
  {
    title: 'Dentist-led',
    text: <Fragment>Our experienced dentists also work in clinics and will provide the very best oral care. You will be assigned a dentist who will look after your treatment from start to finish.<br/>All our dentists are friendly and approachable – if you've got a question, just ask.</Fragment>,
    image: { url: 'pages/comparison_step3.png', alt: 'Dentist led treatment' },
    icon: { url: 'icons/icon_comparison_step3.svg', alt: 'Decorative icon' },
    reverse: false,
  },
  {
    title: 'Our lab',
    text: <Fragment>Our competitors outsource the manufacture of their products, but we look after everything in our own lab. We're at the forefront of aligner research and development, so our users benefit from the latest innovations and a truly personalised service.</Fragment>,
    image: { url: 'pages/comparison_step4.png', alt: 'Our own lab' },
    icon: { url: 'icons/icon_comparison_step4.svg', alt: 'Decorative icon' },
    reverse: true,
  },
  {
    title: 'Our guarantee',
    text: <Fragment>Using our unique app, a real dentist can assess your suitability before getting started, risk free. Successful and safe treatment starts with good screening.</Fragment>,
    image: { url: 'pages/comparison_step5.png', alt: 'Unique guarantees' },
    icon: { url: 'icons/icon_comparison_step5.svg', alt: 'Decorative icon' },
    reverse: false,
  },
  {
    title: 'Regular reviews',
    text: <Fragment>Throughout your treatment, the clinical team will assess and track your progress monthly, and order your next aligners according to your tooth movement.</Fragment>,
    image: { url: 'pages/comparison_step6.png', alt: 'Regular progress reviews' },
    icon: { url: 'icons/icon_comparison_step6.svg', alt: 'Decorative icon' },
    reverse: true,
  },
  {
    title: 'Bespoke service',
    text: <Fragment>We're all different, so if you need more aligners or more time don't worry. It's all included in your one-off fee - unlike many of our competitors who charge for extra aligners.</Fragment>,
    image: { url: 'pages/comparison_step7.png', alt: 'Bespoke service' },
    icon: { url: 'icons/icon_comparison_step7.svg', alt: 'Decorative icon' },
    reverse: false,
  },
  {
    title: 'No fee guarantee',
    text: <Fragment>Lost or broken aligners? No problem. We'll get it sorted and get you back on track in no time. At no extra cost.</Fragment>,
    image: { url: 'pages/comparison_step8.png', alt: 'No fee guarantee' },
    icon: { url: 'icons/icon_comparison_step8.svg', alt: 'Decorative icon' },
    reverse: true,
  },
];
