import React from 'react';
import PropTypes from 'prop-types';

import { Cover, Layout, Link, SectionCompare, SectionTopics, SEO } from 'components';
import { routes } from 'utils/routes';
import { compareData } from 'utils/config/compare';

import content from './teeth-straightening-comparator.yml';

const ComparePage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale}>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <Cover
      title='The Straight Teeth difference.'
      image={{ url: 'pages/comparison_cover.jpg', alt: 'Our dentists' }}
      text="Lots of teeth straightening services look the same. But we’re a bit different.<br/><br/>From our innovative tech to the all-important price promise – explore the reasons why below."
      className='bg-img-grid-faded'
    />

    <div className='flex-center'>
      <Link to={routes.getStarted.path} {...routes.getStarted} className='button cta-comparison'>Get started</Link>
    </div>

    <p className='t-center mt-40 mb-40'><b>Pick a topic or just scroll down to find out more.</b></p>

    <SectionTopics />

    <div className='section-compare fvw'>
      {compareData.map(({ title, text, image, icon, reverse }, idx) => (
        <SectionCompare key={idx} title={title} text={text} image={image} icon={icon} reverse={reverse} />
      ))}
    </div>
  </Layout>
);

ComparePage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default ComparePage;
